import React, {useState} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import communityEventsStyles from "./community-events.module.css";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import formStyle from "./form.module.css";
import addToMailchimp from "gatsby-plugin-mailchimp";
import VideoAudioControl from "../components/videoAudioControl";

const CommunityEventsPage = ({data}) => {

  const [formState, setFormValues] = useState({email: ""});
  const [showSubscribeForm, setShowSubscribeForm] = useState(false);
  const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
  const [showMailchimpError, setMailchimpErrorMessage] = useState({show: false, message: ""});
  const [showMailchimpSuccess, setMailchimpSuccessMessage] = useState({show: false, message: ""});
  const [formResult, setFormResult] = useState({});
  const [subscribeMailchimpUrl, setSubscribeMailchimpUrl] = useState("");

  let submitUrl = '';
  const mailchimBaseUrl = "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=";
  const submitUrls = {
    "san-diego": `${mailchimBaseUrl}198f7c46aa`,
    "los-angeles": `${mailchimBaseUrl}a3100b0a4c`,
    "orange-county": `${mailchimBaseUrl}6095f963ce`,
    "san-francisco": `${mailchimBaseUrl}7992412046`,
    "san-jose": `${mailchimBaseUrl}e9bc91ec0a`,
    "east-bay": `${mailchimBaseUrl}b4b76a97f6`,
    "sacremento": `${mailchimBaseUrl}6aad2f3cde`,
  };

  const selectCity = (city) => {
    submitUrl = submitUrls[city];
    setShowSubscribeForm(true);
    setSubscribeMailchimpUrl(submitUrl);
  };

  const resetFormAfterSubmit = () => {
    setFormValues({
      email: "",
    });
  };

  const handleSubscribeForm = async (e) => {
    e.preventDefault();

    try {
      const requiredFields = ["email"];

      for (const field in formState) {
        if(requiredFields.indexOf(field) !== -1) {
          if(!formState[field]) {
            setShowErrorAlert({
              show: true,
              onField: field.charAt(0).toUpperCase()+ field.slice(1)
            });

            return;
          }
        }
      }

      const result = await addToMailchimp(formState.email, {
      }, subscribeMailchimpUrl);
      setFormResult(result);

      if (result.result === 'success') {
        setShowSubscribeForm(false);
        setMailchimpSuccessMessage({
          show: true,
          message: result.msg
        });
        resetFormAfterSubmit();
      } else {
        setMailchimpErrorMessage({
          show: true,
          message: result.msg
        });
      }
    } catch (e) {
    }
  };

  const handleSubscribeFormChange = (e) => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

    return (
      <Layout className="site-content">
        <Container>

          <div className="workshop">
            <div className="title">
              <h1 className={globalStyle.entryTitle}>Community Events</h1>
            </div>

            <div className="workshop-content">
              <Row className="workshopDescription">
                <Col xs={12} md={8} sm={12}>
                  <Row>
                    {data.vimeoVideos.edges.map(item => (
                    <Col key={item.node.id} className={communityEventsStyles.communityEventsVideo}>
                      <iframe id={item.node.id}
                        src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;loop=0;autopause=0&amp;player_id=0&amp;app_id=163410"}
                        width="640"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="Community Events">
                      </iframe>
                      <VideoAudioControl></VideoAudioControl>
                    </Col>
                    ))}
                  </Row>
                </Col>

                <Col xs={12} md={4} sm={12}>
                  <h4>About Events</h4>
                    <div className="topicsList">
                      <ul>
                        <li>We are here to bring Moms together and support each other.</li>
                        <li>You will find
                            information and dates for our upcoming MOM events and our Partner events below.</li>
                        <li>Keep a look out for updates on what we do in our communities each month.</li>
                      </ul>
                    </div>
                </Col>
              </Row>

              <Row className={communityEventsStyles.communityEventsDescription}>
                <Col>
                <p>
                  Our events range from daytime and weekend gatherings with expert speakers and networking,
                  to Moms evenings out and shopping nights.
                  Babies are always welcome!
                </p>
                <p>We can’t wait to see you in person at an event soon!</p>
                <p>
                  Please subscribe to our event emails for your city and stay up the minute on fun and inspirational
                  Moms on Maternity happenings near you.
                </p>

                <h3>Stay informed about our community events!</h3>

                <ul className={communityEventsStyles.cityList}>
                  <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, communityEventsStyles.selectCity]} onClick={() => {selectCity('san-diego')}}>San Diego</Button></li>
                  <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, communityEventsStyles.selectCity]} onClick={() => {selectCity('los-angeles')}}>Los Angeles</Button></li>
                  <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, communityEventsStyles.selectCity]} onClick={() => {selectCity('orange-county')}}>Orange County</Button></li>
                  <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, communityEventsStyles.selectCity]} onClick={() => {selectCity('san-francisco')}}>San Francisco</Button></li>
                  <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, communityEventsStyles.selectCity]} onClick={() => {selectCity('san-jose')}}>San Jose</Button></li>
                  <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, communityEventsStyles.selectCity]} onClick={() => {selectCity('east-bay')}}>East Bay</Button></li>
                  <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, communityEventsStyles.selectCity]} onClick={() => {selectCity('sacremento')}}>Sacramento</Button></li>
                </ul>

                {showSubscribeForm  &&
                <Form onSubmit={handleSubscribeForm} className={communityEventsStyles.subscribeForm} name="subscribe" xs={12} md={12} sm={12}>
                  <Form.Row>
                    <Col xs={12} md={12} sm={12}>
                      <Form.Group as={Col} controlId="formEmail">
                        <Form.Control size="sm" value={formState.email} name="email" type="text"
                                      placeholder="Enter your email address" onChange={handleSubscribeFormChange}/>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={12} sm={12}>
                      <Button type="submit" className={[formStyle.btnPrimary, formStyle.submitButtonOrange, communityEventsStyles.subscribeButton]}>
                        Subscribe
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
                }

                {
                  showError.show &&
                  <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
                  >{showError.onField} field should not be empty</Alert>
                }
                {
                  showMailchimpError.show &&
                  <Alert variant="danger" onClose={() => setMailchimpErrorMessage({show: false})} dismissible
                  >{showMailchimpError.message}</Alert>
                }
                {
                  showMailchimpSuccess.show &&
                  <Alert variant="success" onClose={() => setMailchimpSuccessMessage({show: false})} dismissible
                  >{showMailchimpSuccess.message}</Alert>
                }
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </Layout>
    )
};

export const query = graphql`
  query {
    vimeoVideos: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "community-events"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
  }
`;

export default CommunityEventsPage
